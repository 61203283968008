import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import Loading from "../../components/Loading"
import theme from "../../theme/theme"

import moment from "moment"
import Values from "values.js"

import classNames from "classnames"
import Tilt from "react-parallax-tilt"
import "./styles/backpack.scss"

import TimeCapsule from "../../components/TimeCapsule"
import BackpackSidebar from "./BackpackSidebar"
import ContentRow from "./ContentRow"

import BackpackProps, { CategoryItem } from "../../types/backpack"

import {
  fetchBackpack,
  fetchCompletedCourses,
  fetchCompletedLPs,
  fetchEarnedBadges,
  fetchEventsAttended,
  fetchExternalApprovals,
  fetchMandatedTrainingHistory,
  fetchUserRubrics,
} from "../../actions/backpack"

import { Dialog } from "@mui/material"
import { UserEventsLearner } from "../../types/events"
import { Categories } from "../../types/taxonomy/category"
import UserLabel from "../UserLabel"
import ConferenceSessionsModal from "./ConferenceSessionsModal"
import UserRubricModal from "./UserRubricModal"

type DateHeaderProps = {
  backpack: BackpackProps
  // eslint-disable-next-line
  session: any
}

const DateHeader = (props: DateHeaderProps) => {
  const { backpack, session } = props
  const { dateRange, dateMin, dateMax } = backpack.filters

  let dateFromFormat = "MMMM Do"
  if (dateRange !== "all") {
    if (
      moment().startOf("week").format("YYYY") !==
      moment().endOf("week").format("YYYY")
    ) {
      dateFromFormat = "MMMM Do YYYY"
    }
  }
  const schoolStart =
    session.group.field_rewind_begin &&
    session.group.field_rewind_begin[0] &&
    session.group.field_rewind_begin[0].value
  const schoolEnd =
    session.group.field_rewind_end &&
    session.group.field_rewind_end[0] &&
    session.group.field_rewind_end[0].value

  return (
    <header className="date-header">
      {dateRange === "all" && <>All Time PD Credit</>}
      {dateRange === "week" && (
        <>
          {moment().startOf("week").format(dateFromFormat)} -{" "}
          {moment().endOf("week").format("MMMM Do, YYYY")}
        </>
      )}
      {dateRange === "month" && (
        <>
          {moment().startOf("month").format(dateFromFormat)} -{" "}
          {moment().endOf("month").format("MMMM Do, YYYY")}
        </>
      )}
      {dateRange === "year" && (
        <>
          {moment().startOf("year").format(dateFromFormat)} -{" "}
          {moment().endOf("year").format("MMMM Do, YYYY")}
        </>
      )}
      {dateRange === "schoolYear" && (
        <>
          {moment(schoolStart).format("MMMM Do, YYYY")} -{" "}
          {moment(schoolEnd).format("MMMM Do, YYYY")}
        </>
      )}
      {dateRange === "custom" && (
        <>
          {dateMin ? (
            <>{moment(JSON.parse(dateMin)).format("MMMM Do YYYY")}</>
          ) : (
            <>Beginning of Time</>
          )}
          {dateMax && (
            <> - {moment(JSON.parse(dateMax)).format("MMMM Do, YYYY")}</>
          )}
        </>
      )}
    </header>
  )
}

type LayoutProps = {
  backpack: BackpackProps
  userId?: number

  // eslint-disable-next-line
  changeNavbar?: (tab: string) => { payload: any; type: "navbar/changeNavbar" }
  categories: Categories
  // eslint-disable-next-line
  goTo?: (location: string) => any

  // eslint-disable-next-line
  session: any
  updateFilters?: (params: {
    dateRange: string
    category: number[]
    // eslint-disable-next-line
  }) => { payload: any; type: "backpack/updateFilters" }
  setDateMin?: (arg: string) => { payload: string; type: "backpack/setDateMin" }
  setDateMax?: (arg: string) => { payload: string; type: "backpack/setDateMax" }
  setSaveDateSetting?: (arg: boolean) => {
    payload: boolean
    type: "backpack/setSaveDateSetting"
  }

  setEventsCarousel: (index: number) => void
  increaseEventsPage: (item: number) => void

  setXPDCarousel?: (index: number) => void
  increaseXPDPage?: (item: number) => void

  setBadgeCarousel: (index: number) => void
  increaseBadgePage: (item: number) => void

  setCourseCarousel: (index: number) => void
  increaseCoursePage: (item: number) => void

  setLPCarousel: (index: number) => void
  increaseLPPage: (item: number) => void

  setMandatedTrainingCarousel: (index: number) => void
  increaseMandatedTrainingPage: (item: number) => void

  setRubricCarousel: (index: number) => void
  increaseRubricPage: (item: number) => void
  fetchSingleUserRubric?: (userRubricId: string) => void
  clearBackpackRubric: () => void

  // eslint-disable-next-line
  setExternalModal?: any
  // eslint-disable-next-line
  getConferenceSessions?: (id: UserEventsLearner) => any
}

const BackpackLayout = (props: LayoutProps) => {
  const { session, backpack, userId, changeNavbar } = props

  const { orgRoles, groupRoles } = session
  const allRoles = orgRoles.concat(groupRoles).join(",")

  const isAdmin =
    allRoles.includes("organization-admin") || allRoles.includes("group-admin")
  const isObserver =
    allRoles.includes("observer") || allRoles.includes("observation")

  const isRubricsOnly = Boolean(
    isObserver && !allRoles.includes("reporter") && !isAdmin && userId
  )
  const dispatch = useDispatch()
  const [modalConferenceID, setModalConferenceId] = useState("")

  if (changeNavbar) {
    dispatch(changeNavbar("backpack"))
  }

  useEffect(() => {
    if (!backpack.fetched) {
      if (!isRubricsOnly) {
        dispatch(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fetchEventsAttended(backpack.filters, userId)
        )

        dispatch(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fetchMandatedTrainingHistory(backpack.filters, userId)
        )
        dispatch(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fetchExternalApprovals(backpack.filters, userId)
        )

        dispatch(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fetchCompletedCourses(backpack.filters, userId)
        )
        dispatch(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fetchEarnedBadges(backpack.filters, userId)
        )
        dispatch(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fetchCompletedLPs(backpack.filters, userId)
        )
        dispatch(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fetchBackpack(backpack.filters, userId)
        )
        if (isObserver || !userId) {
          dispatch(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            fetchUserRubrics(backpack.filters, userId)
          )
        }
      } else {
        dispatch(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fetchUserRubrics(backpack.filters, userId, isRubricsOnly)
        )
      }
    }
  }, [backpack.fetched, isRubricsOnly, backpack.filters, userId, dispatch])

  const topCategories = backpack.categoryData.slice(0, 3)
  const hours =
    backpack.totalTime >= 60
      ? Math.floor(backpack.totalTime / 60).toLocaleString()
      : 0
  const minutes = backpack.totalTime % 60

  const [isCardFlipped, flipCard] = useState(false)

  enum ContentType {
    Events = "events",
    External = "external",
    Courses = "courses",
    Badges = "badges",
    LearningPaths = "learningPaths",
    UserRubrics = "userRubrics",
    MandatedTraining = "mandatedTraining",
  }

  const orderedSections = [
    "events",
    "external",
    "courses",
    "badges",
    "learningPaths",
    "userRubrics",
    "mandatedTraining",
  ]

  if (
    (backpack.events.fetched &&
      backpack.external.fetched &&
      backpack.courses.fetched &&
      backpack.badges.fetched &&
      backpack.mandatedTraining.fetched &&
      backpack.learningPaths.fetched) ||
    (isObserver && backpack.userRubrics.fetched)
  ) {
    if (!backpack.events.data.length) {
      orderedSections.push(
        orderedSections.splice(orderedSections.indexOf("events"), 1)[0]
      )
    }
    if (!backpack.external.data.length) {
      orderedSections.push(
        orderedSections.splice(orderedSections.indexOf("external"), 1)[0]
      )
    }

    if (!backpack.courses.data.length) {
      orderedSections.push(
        orderedSections.splice(orderedSections.indexOf("courses"), 1)[0]
      )
    }
    if (!backpack.badges.data.length) {
      orderedSections.push(
        orderedSections.splice(orderedSections.indexOf("badges"), 1)[0]
      )
    }
    if (!backpack.learningPaths.data.length) {
      orderedSections.push(
        orderedSections.splice(orderedSections.indexOf("learningPaths"), 1)[0]
      )
    }
    if (!backpack.mandatedTraining.data.length) {
      orderedSections.push(
        orderedSections.splice(
          orderedSections.indexOf("mandatedTraining"),
          1
        )[0]
      )
    }
    if (!backpack.userRubrics.data.length && !isRubricsOnly) {
      orderedSections.push(
        orderedSections.splice(orderedSections.indexOf("userRubrics"), 1)[0]
      )
    }
  }

  let isReady =
    backpack.fetched &&
    backpack.events.fetched &&
    backpack.mandatedTraining.fetched &&
    backpack.external.fetched &&
    backpack.courses.fetched &&
    backpack.badges.fetched &&
    backpack.learningPaths.fetched
  if (isObserver) {
    isReady = isReady && backpack.userRubrics.fetched
  }

  if (isRubricsOnly) {
    isReady = backpack.userRubrics.fetched
  }

  return (
    <div
      className={classNames(
        "page backpack-layout",
        isRubricsOnly && "rubricsOnly"
      )}
    >
      {/**
       * User Rubric Modal
       */}
      <Dialog
        onClose={props.clearBackpackRubric}
        id="user-rubric-modal"
        open={backpack.activeRubric ? true : false}
      >
        <UserRubricModal
          clearBackpackRubric={props.clearBackpackRubric}
          backpack={backpack}
        />
      </Dialog>

      <BackpackSidebar {...props} />

      {modalConferenceID && (
        <ConferenceSessionsModal
          getConferenceSessions={props.getConferenceSessions}
          setModalConferenceId={setModalConferenceId}
          conferenceId={modalConferenceID}
          events={backpack.events.data}
          userId={userId}
        />
      )}

      {isReady && backpack.userData.field_first_name_value && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Tilt
          tiltMaxAngleY={3}
          tiltMaxAngleX={3}
          className={classNames(
            "tilt-card",
            !topCategories.length && "no-categories"
          )}
          reset={true}
          flipHorizontally={isCardFlipped}
          transitionSpeed={1000}
          tiltReverse={true}
        >
          <div
            id="overview-wrapper"
            className={classNames(isCardFlipped && "flipped")}
            onClick={() => {
              !isRubricsOnly && flipCard(!isCardFlipped)
            }}
          >
            <div id="backpack-overview-card">
              <div
                style={{ padding: "15px 25px", flex: 1, position: "relative" }}
              >
                <div className="flexRow userContainer">
                  <div className="foil" />
                  <div
                    style={{
                      position: "relative",
                      zIndex: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <UserLabel
                      firstName={backpack.userData.field_first_name_value}
                      lastName={backpack.userData.field_last_name_value}
                      imagePath={backpack.userData.user_image}
                      subgroups={backpack.userData.user_subgroups}
                    />
                  </div>
                </div>
                {!isRubricsOnly && (
                  <>
                    <strong
                      style={{ opacity: backpack.fetched ? 1 : 0 }}
                      className="totalTime"
                    >
                      {Number(hours) > 0 && <>{hours}h</>} {minutes}m
                    </strong>
                    <div style={{ opacity: backpack.fetched ? 1 : 0 }}>
                      <DateHeader
                        session={props.session}
                        backpack={props.backpack}
                      />
                    </div>
                  </>
                )}
              </div>

              <ul style={{ flexDirection: "column" }} className="topCategories">
                {topCategories.map((cat: CategoryItem) => {
                  const categoryHours =
                    cat.total_time >= 60
                      ? Math.floor(cat.total_time / 60).toLocaleString()
                      : 0
                  const categoryMinutes = cat.total_time % 60
                  if (cat.tid && cat.total_time) {
                    return (
                      <li
                        key={cat.tid}
                        style={{
                          backgroundColor: new Values(
                            theme.palette.primary.main
                          )
                            .tint(70)
                            .hexString(),
                          display: "flex",
                        }}
                      >
                        <div
                          className="categoryIcon"
                          style={{
                            backgroundImage: "url(" + cat.category_image + ")",
                          }}
                        />
                        <div style={{ marginLeft: 5 }}>
                          <strong style={{ display: "block" }}>
                            {cat.category_name}
                          </strong>
                          <TimeCapsule
                            hours={Number(categoryHours)}
                            minutes={categoryMinutes}
                          />
                        </div>
                      </li>
                    )
                  }
                  return null
                })}
              </ul>

              {!isRubricsOnly && (
                <div className="popover">
                  <p style={{ backgroundColor: theme.palette.secondary.main }}>
                    <span className="icon pointer" />
                    Click to view PD across all categories.
                  </p>
                </div>
              )}
            </div>

            <div className="card-back">
              <div className="back-header">
                <div className="stripe">
                  <DateHeader
                    session={props.session}
                    backpack={props.backpack}
                  />
                  <strong style={{ color: "white" }} className="totalTime">
                    {Number(hours) > 0 && <>{hours.toLocaleString()}h</>}{" "}
                    {minutes}m
                  </strong>
                </div>
              </div>

              {
                <ul
                  style={{
                    flexDirection: "column",
                    minHeight: backpack.categoryData.length ? 0 : 200,
                  }}
                  className="topCategories allCategories"
                >
                  {backpack.categoryData.map((cat: CategoryItem) => {
                    const categoryHours =
                      cat.total_time >= 60
                        ? Math.floor(cat.total_time / 60).toLocaleString()
                        : 0
                    const categoryMinutes = cat.total_time % 60

                    if (cat.tid && cat.total_time) {
                      return (
                        <li
                          key={cat.tid}
                          style={{
                            backgroundColor: "white",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="categoryIcon"
                            style={{
                              backgroundImage:
                                "url(" + cat.category_image + ")",
                            }}
                          />
                          <div style={{ marginLeft: 5, flex: 1 }}>
                            <strong
                              style={{ display: "block", marginBottom: -5 }}
                            >
                              {cat.category_name}
                            </strong>
                          </div>
                          <TimeCapsule
                            hours={Number(categoryHours)}
                            minutes={categoryMinutes}
                          />
                        </li>
                      )
                    }
                    return null
                  })}
                </ul>
              }
            </div>
          </div>
        </Tilt>
      )}
      <div
        style={{
          backgroundColor: "black",
          transition: ".4s",
          opacity: isCardFlipped ? 0.6 : 0,
          position: "fixed",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          pointerEvents: isCardFlipped ? "all" : "none",
          cursor: "pointer",
          zIndex: 1,
        }}
        onClick={() => flipCard(false)}
      />

      {isReady ? (
        <>
          {Object.values(ContentType).map((section, index) => {
            let contentBase
            let shouldReturn = true
            if (section === "events") {
              contentBase = backpack.events
            }
            if (section === "external") {
              contentBase = backpack.external
            }
            if (section === "courses") {
              contentBase = backpack.courses
            }
            if (section === "badges") {
              contentBase = backpack.badges
            }
            if (section === "learningPaths") {
              contentBase = backpack.learningPaths
            }

            if (section === "userRubrics") {
              contentBase = backpack.userRubrics
              if (!backpack.userRubrics.data.length && !isRubricsOnly) {
                shouldReturn = false
              }
            }
            if (section === "mandatedTraining") {
              contentBase = backpack.mandatedTraining
              if (!backpack.mandatedTraining.data.length) {
                shouldReturn = false
              }
            }

            if (
              section === "badges" ||
              section === "learningPaths" ||
              section === "mandatedTraining" ||
              section === "userRubrics"
            ) {
              if (backpack.filters.category.length > 0) {
                shouldReturn = false
              }
            }

            if (isRubricsOnly && section !== "userRubrics") {
              shouldReturn = false
            } else if (!isObserver && userId && section === "userRubrics") {
              shouldReturn = false
            }

            if (shouldReturn && contentBase) {
              return (
                <ContentRow
                  backpack={props.backpack}
                  categories={props.categories}
                  changeNavbar={props.changeNavbar}
                  goTo={props.goTo}
                  session={props.session}
                  setEventsCarousel={props.setEventsCarousel}
                  increaseEventsPage={props.increaseEventsPage}
                  setXPDCarousel={props.setXPDCarousel}
                  increaseXPDPage={props.increaseXPDPage}
                  setBadgeCarousel={props.setBadgeCarousel}
                  increaseBadgePage={props.increaseBadgePage}
                  setCourseCarousel={props.setCourseCarousel}
                  increaseCoursePage={props.increaseCoursePage}
                  setLPCarousel={props.setLPCarousel}
                  increaseLPPage={props.increaseLPPage}
                  setRubricCarousel={props.setRubricCarousel}
                  increaseMandatedTrainingPage={
                    props.increaseMandatedTrainingPage
                  }
                  setMandatedTrainingCarousel={
                    props.setMandatedTrainingCarousel
                  }
                  increaseRubricPage={props.increaseRubricPage}
                  fetchSingleUserRubric={props.fetchSingleUserRubric}
                  setExternalModal={props.setExternalModal}
                  setModalConferenceId={setModalConferenceId}
                  // eslint-disable-next-line
                  // @ts-ignore
                  setDateMin={props.setDateMin}
                  // eslint-disable-next-line
                  // @ts-ignore
                  setDateMax={props.setDateMax}
                  // {...props}
                  print={false}
                  key={index}
                  animationDelay={isRubricsOnly ? 0 : index}
                  total={contentBase.totalCompleted}
                  data={contentBase.data}
                  userId={props.userId}
                  contentType={section}
                />
              )
            }
            return null
          })}
        </>
      ) : (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className="backpack-buddy">
            <div className="star" />
            <div className="star" />
            <div className="star" />
          </div>
          <Loading
            message={
              !userId ? "Generating your backpack..." : "Generating backpack..."
            }
          />
        </div>
      )}
    </div>
  )
}

export default BackpackLayout
