import theme from "../theme/theme"
import Values from "values.js"
import UserInitial from "../components/UserInitial"
import "../styles/reviewer-quote.scss"

interface QuoteProps {
  groupLabel?: string
  text: string
  firstName: string
  lastName: string
  imageSrc: string | null
}

const ReviewerQuote = ({
  groupLabel,
  imageSrc,
  firstName,
  lastName,
  text,
}: QuoteProps) => {
  const backgroundColor = new Values(theme.palette.secondary.main)
    .tint(70)
    .hexString()

  return (
    <div className="reviewer-quote">
      <div className="flexRow">
        <div
          style={{
            marginRight: 10,
            width: 50,
            backgroundColor: theme.palette.secondary.main,
            height: 50,
            borderRadius: 50,
            boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.24)",
          }}
        >
          {imageSrc ? (
            <div
              style={{
                width: 50,
                backgroundColor: theme.palette.secondary.main,
                height: 50,
                borderRadius: 50,
                backgroundImage: imageSrc ? "url(" + imageSrc + ")" : "none",
                backgroundSize: "cover",
              }}
            />
          ) : (
            <div style={{ marginRight: 10 }}>
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <UserInitial
                  fontSize={20}
                  size={50}
                  initials={
                    firstName.charAt(0).toUpperCase() +
                    lastName.charAt(0).toUpperCase()
                  }
                />
              }
            </div>
          )}
        </div>
        <div>
          <strong style={{ display: "block", marginBottom: -5 }}>
            {firstName} {lastName}
          </strong>
          <span
            style={{ textTransform: "uppercase", opacity: 0.5, fontSize: 12 }}
          >
            {groupLabel}
          </span>
        </div>
      </div>

      <div
        className="quote-bubble"
        style={{
          marginTop: 15,
          marginLeft: 1,
          backgroundColor,
          padding: "10px 15px",
          borderRadius: 8,
          display: "inline-block",
        }}
      >
        <div
          className="triangle"
          style={{ borderBottomColor: backgroundColor }}
        />
        &quot;{text}&quot;
      </div>
    </div>
  )
}

export default ReviewerQuote
