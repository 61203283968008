import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Card, Button } from "@mui/material"
import { connect, useDispatch } from "react-redux"
import classNames from "classnames"
import { getCategoryLabel } from "../../functions"
import { generateTimestamp } from "@mobilemind/common/src/functions/index"

import Rating from "@mobilemind/common/src/components/Rating"
import PropTypes from "prop-types"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import { getSingleLP } from "../learningPaths/learningPathsSlice"

const mapStateToProps = ({
  mandatedTraining,
  learningPaths,
  categories,
  announcements,
}) => {
  return {
    learningPaths: learningPaths.data,
    announcements,
    mandatedTraining,
    categories,
    announcementTypes: announcements.types,
  }
}

function WidgetAnnouncement(props) {
  const {
    learningPaths,
    mandatedTraining,
    announcements,
    categories,
    announcementTypes,
    theme,
  } = props
  const { announcement } = props.widget

  const [learningPathFetched, setLearningPathFetched] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      !learningPathFetched &&
      announcement.field_learning_path &&
      announcement.field_learning_path[0]
    ) {
      setLearningPathFetched(true)
      dispatch(
        getSingleLP({ id: announcement.field_learning_path[0].target_id })
      )
    } else {
      setLearningPathFetched(true)
    }
  }, [learningPathFetched, announcement.field_learning_path, dispatch])

  let image, video
  if (announcement.field_media[0]) {
    image = announcements.images.find(
      (image) => image.fid[0].value === announcement.field_media[0].target_id
    )
  }
  if (announcement.field_video[0]) {
    video = announcements.videos.find(
      (video) => video.fid[0].value === announcement.field_video[0].target_id
    )
  }

  let course, categoryLabel, category, coursePath, learningPath, training
  let type = announcementTypes.find(
    (type) =>
      type.tid[0].value === announcement.field_announcement_type[0].target_id
  )
  let label = type.name[0].value
  let isTip = label === "Tip of the day"
  let isFeaturedCourse = label === "Featured Course"
  let isFeaturedLearningPath = label === "Featured Learning Path"
  let isMandatedTraining = label === "Mandated Training"

  let imageSrc = image && process.env.REACT_APP_API_URL + image.uri[0].url
  let videoSrc = video && process.env.REACT_APP_API_URL + video.uri[0].url

  if (isFeaturedCourse) {
    course = announcements.courses.find(
      (course) =>
        announcement.field_course[0] &&
        course.uuid[0].value === announcement.field_course[0].target_uuid
    )

    if (course) {
      category = categories.data.find(
        (cat) => cat.id === course.field_category[0].target_uuid
      )
      categoryLabel = getCategoryLabel(
        categories,
        course.field_category[0].target_uuid
      )
      coursePath = "/course/" + course.id[0].value
    }
  }
  if (isFeaturedLearningPath) {
    learningPath = learningPaths.find((path) => {
      return (
        announcement.field_learning_path[0] &&
        path.field_lp_uuid === announcement.field_learning_path[0].target_uuid
      )
    })
  }

  if (isMandatedTraining) {
    training = mandatedTraining.data.find(
      (training) =>
        training.uuid ===
        announcement.field_renewable_course_series[0].target_uuid
    )
  }

  return (
    <>
      {!isFeaturedLearningPath ? (
        <Card
          className={classNames(
            "widget announcement",
            isFeaturedCourse && "featuredCourse",
            isMandatedTraining && "isMandatedTraining"
          )}
          style={{ animationDelay: props.animationDelay }}
        >
          <div
            className={classNames("widgetLabel", label.toLowerCase())}
            style={{ backgroundColor: theme.palette.primary.main }}
          >
            {label}
          </div>
          {!isTip && !isMandatedTraining && (
            <div className="timestamp">
              {generateTimestamp(announcement.created[0].value, "dateOnly")}
            </div>
          )}
          {!isTip && !isMandatedTraining && (
            <h2>{announcement.title[0].value}</h2>
          )}
          {imageSrc &&
            !isTip &&
            !isFeaturedCourse &&
            !isFeaturedLearningPath && (
              <img
                src={imageSrc}
                alt="Partner Content Announcement"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: 8,
                  marginTop: 15,
                }}
              />
            )}

          {training && (
            <div
              className="announcementImage trainingImage"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                marginTop: 0,
                backgroundImage: "url(" + training.rcs_image + ")",
              }}
            >
              <span className="icon mandated" />
              <h2>{training.name}</h2>
            </div>
          )}

          {video && (
            <div className="videoWrapper">
              <video controls>
                <source src={videoSrc} type="video/mp4" />
              </video>
            </div>
          )}
          {isTip && (
            <div className="flexRow">
              <div>
                <div className="icon tip" />
              </div>
              {announcement.attributes.body && (
                <SanitizedHTML
                  html={announcement.body[0].value.replaceAll("<br>", "")}
                />
              )}
            </div>
          )}

          {!isTip &&
            announcement.body[0] &&
            !isFeaturedCourse &&
            !isFeaturedLearningPath && (
              <SanitizedHTML
                html={announcement.body[0].value.replaceAll("<br>", "")}
              />
            )}

          {isMandatedTraining && training && (
            <footer>
              <Link to={"/mandated-training/" + training.rcs_id}>
                <Button
                  style={{ color: theme.palette.primary.main }}
                  className="button small"
                >
                  View Training
                </Button>
              </Link>
            </footer>
          )}

          {isFeaturedCourse && course && (
            <>
              <div className="card-image" data-bg={categoryLabel}>
                <header>
                  {category && (
                    <div className="category-icon-wrapper">
                      <span
                        className="category-icon"
                        style={{
                          backgroundImage:
                            "url(" +
                            process.env.REACT_APP_API_URL +
                            category.image.attributes.uri.url +
                            ")",
                        }}
                      />
                    </div>
                  )}
                  <div className="course-label">
                    <span className="category-label">{categoryLabel}</span>
                    <div className="course-level">
                      <Rating
                        level={course.field_level[0].value.toLowerCase()}
                      />
                    </div>
                  </div>
                </header>
                <h2>{course.name[0].value}</h2>
              </div>
              <p>{course.field_course_objective[0].value}</p>
              <footer>
                <Link to={coursePath}>
                  <Button
                    style={{ color: theme.palette.primary.main }}
                    className="button small"
                  >
                    View Course
                  </Button>
                </Link>
              </footer>
            </>
          )}
        </Card>
      ) : (
        <>
          {learningPath && (
            <Card
              className={classNames("widget announcement featuredLearningPath")}
            >
              {label && (
                <div className={classNames("widgetLabel", label.toLowerCase())}>
                  {label}
                </div>
              )}
              <div className="timestamp">
                {generateTimestamp(announcement.created[0].value, "dateOnly")}
              </div>
              <Link to={"/learning-path/" + learningPath.field_lp_id}>
                <div
                  className="card-image"
                  style={{
                    backgroundImage:
                      "url(" + learningPath.field_lp_image_uri + ")",
                  }}
                />
                <h2>{learningPath.name}</h2>
                <div style={{ color: "black" }}>
                  <SanitizedHTML html={announcement.body[0].value} />
                </div>
                <footer>
                  <Button
                    style={{ color: theme.palette.primary.main }}
                    className="button small"
                  >
                    View Learning Path
                  </Button>
                </footer>
              </Link>
            </Card>
          )}
        </>
      )}
    </>
  )
}

WidgetAnnouncement.propTypes = {
  animationDelay: PropTypes.string,
  announcementTypes: PropTypes.array,
  categories: PropTypes.object,
  courses: PropTypes.array,
  widget: PropTypes.object,
}

export default connect(mapStateToProps)(WidgetAnnouncement)
