import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"

export const getCompletedLearningPaths = createAsyncThunk(
  "achievementsSlice/getCompletedLearningPaths",
  async (args, thunkAPI) => {
    const { isPartner } = thunkAPI.getState().session

    const url = isPartner
      ? "/api/mm_partner_portal/lp_explore?"
      : "/api/learning_path/explore?"

    let query = {
      status: "completed",
      search:
        args && args.field === "search"
          ? args.value
          : thunkAPI.getState().achievements.learningPaths.filters.search,
    }

    let sort =
      args && args.field === "sort"
        ? args.value
        : thunkAPI.getState().achievements.learningPaths.filters.sort

    if (sort !== "name") {
      query.sort_by = "updated"
      query.sort_order = sort === "newest" ? "DESC" : "ASC"
    }

    // If we're going to the next page, set the offset, otherwise clear it out
    if (args) {
      query.page = args.nextPage
        ? { offset: args.nextPage * 25 }
        : { offset: 0 }
    }

    let response = await fetchWrapper.get(url + qs.stringify(query))

    if (response.ok) {
      let data = await response.json()

      // Remove duplicates for now, this is weird
      const uniqueData = Array.from(
        new Set(data.lp_data.map((a) => a.field_lp_id))
      ).map((field_lp_id) =>
        data.lp_data.find((a) => a.field_lp_id === field_lp_id)
      )

      data.lp_data = uniqueData

      return { data: data.lp_data, total: data.total_records }
    }
  }
)

export const getCompletedCourses = createAsyncThunk(
  "achievementsSlice/getCompletedCourses",
  async (args, thunkAPI) => {
    const { achievements, session } = thunkAPI.getState()
    const { search, category, org, sort } = args
      ? args
      : achievements.courses.filters

    const url = session.isPartner
      ? "/api/mm_partner_portal/course_explore?"
      : "/api/course_entity/explore?"

    let query = {
      search,
      status: "completed",
      archive: true,
      org,
    }

    if (category) {
      query.category = category.attributes.drupal_internal__tid
    }

    // If we're going to the next page, set the offset, otherwise clear it out
    if (args) {
      query.page = args.nextPage
        ? { offset: args.nextPage * 25 }
        : { offset: 0 }
    }

    query.sort_by = sort

    if (sort !== "name") {
      query.sort_by = "completed_date"
      query.sort_order = sort === "dateNewest" ? "DESC" : "ASC"
    }

    let response = await fetchWrapper.get(url + qs.stringify(query))

    if (response.ok) {
      let data = await response.json()

      return data
    }
  }
)

export const getEarnedBadges = createAsyncThunk(
  "achievementsSlice/getEarnedBadges",
  async (args, thunkAPI) => {
    const { sort, search } = args
      ? args
      : thunkAPI.getState().achievements.badges.filters
    const { currentPage } = thunkAPI.getState().achievements.badges
    const { isPartner } = thunkAPI.getState().session

    const url = isPartner
      ? "/api/mm_partner_portal/badges_explore?"
      : "/api/badges_entity/explore?"

    let query = {
      archive: true,
      earned: "earned",
      search,
    }

    if (sort !== "name") {
      query.sort_by = "earned_date"
      query.sort_order = sort === "dateNewest" ? "DESC" : "ASC"
    }

    // If we're going to the next page, set the offset, otherwise clear it out
    if (args) {
      query.page = args.nextPage ? { offset: currentPage * 25 } : { offset: 0 }
    }

    let response = await fetchWrapper.get(url + qs.stringify(query))
    let data = await response.json()

    return { data: data.badges_data, total: data.total_records }
  }
)

export const getEventsAttended = createAsyncThunk(
  "achievementsSlice/getEventsAttended",
  async (args, thunkAPI) => {
    const { achievements } = thunkAPI.getState()
    const { search, sort } = args ? args : achievements.events.filters

    let query = {
      name: search,
      sort_by: sort,
    }

    if (sort !== "name") {
      query.sort_by = "field_event_date_time_value"
      query.sort_order = sort === "dateNewest" ? "DESC" : "ASC"
    } else {
      query.sort_order = "ASC"
    }

    let response = await fetchWrapper.get(
      "/api/user-events-learner/user?" + qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()

      return data
    }
  }
)

export const getExternalApproved = createAsyncThunk(
  "achievementsSlice/getExternalApproved",
  async (args, thunkAPI) => {
    let data = undefined
    const { filters } = thunkAPI.getState().achievements.approvedRequests
    let query = {
      status: "accepted",
      sort_by: "start_date",
      sort_order: "DESC",
      title: args ? args.search : "",
      page: {
        offset: filters.currentPage * 25,
      },
    }

    /**
     * creates an endpoint for viewing user External Events.
     * * /api/mm_ext_usr_event/learn/
     *
     * also supports filters for user name search, category, org,
     * subgroup.
     * * /api/mm_ext_usr_event/learn/?
     * * * status= [str] (pending, accepted, declined, show_accepted)
     * * * &start_date= [str]
     * * * &title= [str]
     * * * &category= [str]
     * * * &page[offset]= [int]
     * * * &sort_by= [str (start_date, title, total_time, category)]
     * * * &sort_order= [str (ASC, DESC)]
     */

    if (args && args.sort === "dateNewest") {
      query.sort_by = "start_date"
      query.sort_order = "DESC"
    }
    if (args && args.sort === "dateOldest") {
      query.sort_by = "start_date"
      query.sort_order = "ASC"
    }
    if (args && args.sort === "title") {
      query.sort_by = "title"
      query.sort_order = "ASC"
    }

    let response = await fetchWrapper.get(
      "/api/mm_ext_usr_event/learn?" + qs.stringify(query)
    )

    if (response.ok) {
      data = await response.json()
      data.external_user_event_data.forEach((event) => {
        event.drupal_internal__id = event.id
        event.id = event.uuid

        event.field_rating_value = Number(event.field_rating_value)
        event.field_total_time_value = Number(event.field_total_time_value)

        // Handle category "Time Blocks," which is our Paragraph with category and time info
        if (event.category_data) {
          event.timeBlocks = event.category_data.map((block) => {
            let totalMinutes = Number(block.category_time_spent)
            return {
              id: block.uuid,
              hours: totalMinutes >= 60 ? Math.floor(totalMinutes / 60) : 0,
              minutes: totalMinutes % 60,
              categoryId: block.category_uuid,
            }
          })
        }

        // Handle attachments
        if (event.attachments_url) {
          event.attachments = event.attachments_url.map((url) => {
            return {
              published: true,
              extension: url.split(".")[url.split(".").length - 1],
              filename: decodeURI(url.split("/")[url.split("/").length - 1]),
              file: url,
            }
          })
        }

        // Handle reviewer information
        if (event.reviewer_data && event.reviewer_data[0]) {
          event.reviewer = event.reviewer_data[0]
        }
      })
      return {
        data: data.external_user_event_data,
        totalPages: data.total_records,
      }
    }
  }
)

export const getConferenceSessions = createAsyncThunk(
  "achievementsSlice/getConferenceSessions",
  async (conference) => {
    let query = {
      sort_by: "field_event_date_time_value",
      sort_order: "ASC",
      field_parent_event_target_id: conference.id_1,
    }

    let response = await fetchWrapper.get(
      "/api/user-events-learner/user?" + qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()

      return data
    }
  }
)

const debouncedGetEventsAttended = debounceThunk(getEventsAttended, 750)
const debouncedGetExternalApproved = debounceThunk(getExternalApproved, 750)
const debouncedGetCompletedCourses = debounceThunk(getCompletedCourses, 750)
const debouncedGetBadgesEarned = debounceThunk(getEarnedBadges, 750)
const debouncedGetCompletedLearningPaths = debounceThunk(
  getCompletedLearningPaths,
  750
)

export const updateEventsFilters = createAsyncThunk(
  "achievementsSlice/updateEventsFilters",
  async (args, thunkAPI) => {
    const currentSearch = thunkAPI.getState().achievements.events.filters.search
    const search = args.search

    if (currentSearch !== search) {
      thunkAPI.dispatch(debouncedGetEventsAttended(args))
    } else {
      thunkAPI.dispatch(debouncedGetEventsAttended(args))
    }
    return args
  }
)

export const updateExternalFilters = createAsyncThunk(
  "achievementsSlice/updateExternalFilters",
  async (args, thunkAPI) => {
    const currentSearch =
      thunkAPI.getState().achievements.approvedRequests.filters.search
    const search = args.search

    if (currentSearch !== search) {
      thunkAPI.dispatch(debouncedGetExternalApproved(args))
    } else {
      thunkAPI.dispatch(debouncedGetExternalApproved(args))
    }
    return args
  }
)

export const increaseXPDPage = createAsyncThunk(
  "achievementsSlice/increaseXPDPage",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(getExternalApproved())
    return true
  }
)

export const updateLearningPathFilters = createAsyncThunk(
  "achievementsSlice/updateLearningPathFilters",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetCompletedLearningPaths(args))
    return args
  }
)

export const updateBadgeFilters = createAsyncThunk(
  "achievementsSlice/updateBadgeFilters",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetBadgesEarned(args))
    return args
  }
)

export const increaseBadgePage = createAsyncThunk(
  "achievementsSlice/increaseBadgePage",
  async (args, thunkAPI) => {
    const { sort } = thunkAPI.getState().achievements.badges.filters
    thunkAPI.dispatch(debouncedGetBadgesEarned({ sort, nextPage: true }))
    return true
  }
)

// Courses
export const updateCourseFilters = createAsyncThunk(
  "achievementsSlice/updateCourseFilters",
  async (args, thunkAPI) => {
    const currentSearch =
      thunkAPI.getState().achievements.courses.filters.search

    const search = args.search

    if (currentSearch !== search) {
      thunkAPI.dispatch(debouncedGetCompletedCourses(args))
    } else {
      thunkAPI.dispatch(getCompletedCourses(args))
    }
    return args
  }
)

export const resetCourseFilters = createAsyncThunk(
  "achievementsSlice/resetCourseFilters",
  async (args, thunkAPI) => {
    const filters = {
      search: "",
      category: null,
      org: "any",
      sort: "dateNewest",
    }

    thunkAPI.dispatch(getCompletedCourses(filters))
    return filters
  }
)

export const increaseCoursePage = createAsyncThunk(
  "achievementsSlice/increaseCoursePage",
  async (args, thunkAPI) => {
    const { filters, currentPage } = thunkAPI.getState().achievements.courses

    const newFilters = { ...filters }
    newFilters.nextPage = currentPage + 1
    thunkAPI.dispatch(debouncedGetCompletedCourses(newFilters))
    return true
  }
)

export const achievementsSlice = createSlice({
  name: "achievementsSlice",
  initialState: {
    courses: {
      data: [],
      currentPage: 0,
      hasFetched: false,
      isFetching: true,
      isFetchingMore: false,
      filters: {
        search: "",
        category: null,
        org: "any",
        sort: "dateNewest",
      },
    },
    learningPaths: {
      fetched: false,
      isFetchingMore: false,
      isFetching: true,
      currentPage: 0,
      totalPages: 1,
      achievements: true,
      data: [],
      filters: {
        sort: "newest",
        search: "",
      },
    },
    badges: {
      fetched: false,
      isFetchingMore: false,
      isFetching: false,
      currentPage: 0,
      totalPages: 1,
      achievements: "true",
      data: [],
      filters: {
        sort: "dateNewest",
        search: "",
      },
    },
    events: {
      filters: {
        search: "",
        sort: "dateNewest",
      },
      data: [],
    },
    approvedRequests: {
      filters: {
        search: "",
        sort: "dateNewest",
      },
      fetched: false,
      isFetchingMore: false,
      isFetching: false,
      currentPage: 0,
      totalPages: 1,
      data: [],
    },
  },
  reducers: {},
  extraReducers: {
    [updateCourseFilters.fulfilled]: (state, action) => {
      state.courses.filters = action.payload
      state.courses.currentPage = 0
    },
    [resetCourseFilters.fulfilled]: (state, action) => {
      state.courses.filters = action.payload
      state.courses.currentPage = 0
      state.courses.data = []
      state.isFetching = true
    },
    [increaseCoursePage.fulfilled]: (state) => {
      state.courses.isFetchingMore = true
      state.courses.currentPage = state.courses.currentPage + 1
    },
    [getEarnedBadges.pending]: (state) => {
      state.badges.isFetching = true
    },
    [updateBadgeFilters.fulfilled]: (state, action) => {
      state.badges.fetched = false
      state.badges.isFetching = true
      state.badges.data = []
      state.badges.currentPage = 0
      state.badges.filters = action.payload
    },
    [updateLearningPathFilters.fulfilled]: (state, action) => {
      state.learningPaths.fetched = false
      state.learningPaths.isFetching = true
      state.learningPaths.data = []
      state.learningPaths.currentPage = 0
      state.learningPaths.filters = action.payload
    },
    [increaseBadgePage.pending]: (state) => {
      state.badges.isFetchingMore = true
      state.badges.currentPage = state.badges.currentPage + 1
    },

    [getCompletedLearningPaths.fulfilled]: (state, action) => {
      state.learningPaths.totalPages = Math.ceil(
        Number(action.payload.total / 25)
      )
      if (action.payload.data.length) {
        if (!state.learningPaths.currentPage) {
          state.learningPaths.data = action.payload.data
        } else {
          state.learningPaths.data = state.learningPaths.data.concat(
            action.payload.data
          )
        }
      }

      state.learningPaths.totalRecords = action.payload.total
      state.learningPaths.hasFetched = true
      state.learningPaths.isFetching = false
      state.learningPaths.isFetchingMore = false
    },

    [getEarnedBadges.fulfilled]: (state, action) => {
      state.badges.total = action.payload.total
      state.badges.totalPages = Math.ceil(Number(action.payload.total / 25))
      if (action.payload.data.length) {
        if (!state.badges.currentPage) {
          state.badges.data = action.payload.data
        } else {
          state.badges.data = state.badges.data.concat(action.payload.data)
        }
      }

      state.badges.fetched = true
      state.badges.isFetching = false
      state.badges.isFetchingMore = false
    },

    [getCompletedCourses.pending]: (state, action) => {
      state.courses.isFetching = true
    },
    [getCompletedCourses.fulfilled]: (state, action) => {
      const { search, category, org } = state.courses.filters

      // If first search
      if (search === "" && !category && org === "any") {
        state.courses.total = action.payload ? action.payload.total_records : 0
        state.courses.totalPages = action.payload
          ? Math.ceil(Number(action.payload.total_records) / 25)
          : 0
      }

      if (!state.courses.currentPage) {
        state.courses.data = action.payload ? action.payload.data : []
      } else {
        state.courses.data = state.courses.data.concat(action.payload.data)
      }

      state.courses.hasFetched = true
      state.courses.isFetching = false
      state.courses.isFetchingMore = false
    },
    [updateEventsFilters.fulfilled]: (state, action) => {
      state.events.filters = action.payload
      state.events.currentPage = 0
    },
    [getEventsAttended.pending]: (state, action) => {
      state.events.data = []
      state.events.fetched = false
    },
    [getEventsAttended.fulfilled]: (state, action) => {
      if (!action.payload.rows.content) {
        state.events.data = action.payload.rows.filter(
          (event) => !event.field_parent_event
        )
      }
      state.events.fetched = true
    },
    [updateExternalFilters.fulfilled]: (state, action) => {
      state.approvedRequests.filters = action.payload
      state.approvedRequests.currentPage = 0
    },
    [increaseXPDPage.pending]: (state) => {
      state.approvedRequests.isFetchingMore = true
      state.approvedRequests.currentPage =
        state.approvedRequests.currentPage + 1
    },
    [getExternalApproved.pending]: (state, action) => {
      state.approvedRequests.fetched = false
    },
    [getExternalApproved.fulfilled]: (state, action) => {
      state.approvedRequests.fetched = true
      state.approvedRequests.isFetchingMore = false
      state.approvedRequests.isFetching = false

      state.approvedRequests.totalPages = action.payload
        ? Math.ceil(Number(action.payload.totalPages) / 25)
        : 0

      if (!state.approvedRequests.currentPage) {
        state.approvedRequests.data = action.payload.data
      } else {
        state.approvedRequests.data = state.approvedRequests.data.concat(
          action.payload.data
        )
      }
    },
  },
})

export default achievementsSlice.reducer
