import React from "react"
import moment, { Moment } from "moment"

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material"

import { useState } from "react"

import { DatePicker } from "@mui/x-date-pickers"

import { PreApprovalFormEntity, PreApprovalQuestion } from "../types"
import theme from "@mobilemind/common/src/theme/theme"

interface PreApprovalFormProps {
  selectedPreApprovalForm: PreApprovalFormEntity
  setResponsesToSubmit: (responses: any) => void
}

export const PreApprovalForm = ({
  selectedPreApprovalForm,
  setResponsesToSubmit,
}: PreApprovalFormProps) => {
  type FormResponses = {
    question: PreApprovalQuestion
    response: any
  }[]

  const formResponseInitial =
    selectedPreApprovalForm?.questions?.map((question) => {
      if (question.type === "paragraph--fixed_choice_questions") {
        return {
          question,
          response: [],
        }
      }

      return {
        question,
        response: "",
      }
    }) || []

  const [formResponses, setFormResponses] =
    useState<FormResponses>(formResponseInitial)

  const { questions } = selectedPreApprovalForm

  function updateResponse({
    index,
    value,
  }: {
    index: number
    value: string | string[] | Moment | null
  }) {
    if (formResponses) {
      const existing = [...formResponses]
      existing[index].response = value

      setFormResponses(existing)
      setResponsesToSubmit(existing)
    }
  }

  return (
    <Box id="pre-approval-form-responses">
      {questions &&
        questions.map((question, index) => {
          const bundle = question.type.split("--")[1]
          const response = formResponses[index].response

          const { field_multi_select, field_answer_op, field_required } =
            question.attributes
          const possibleAnswers = field_answer_op && field_answer_op.split(",")

          return (
            <Box key={question.id} sx={{ marginTop: 3, marginBottom: 5 }}>
              <strong style={{ fontSize: 18 }}>
                {question.attributes.field_question_name}
                {field_required && (
                  <span
                    style={{
                      color: "red",
                      position: "relative",
                      top: -3,
                      right: -3,
                      fontSize: 16,
                    }}
                  >
                    *
                  </span>
                )}
              </strong>
              {bundle === "open_ended_questions" && (
                <FormControl sx={{ marginTop: 3 }}>
                  <TextField
                    placeholder="Enter your response"
                    value={response ?? ""}
                    multiline
                    onChange={(event) => {
                      updateResponse({ index, value: event.target.value })
                    }}
                  />
                </FormControl>
              )}

              {bundle === "date_question" && (
                <FormControl className="date-question" sx={{ marginTop: 3 }}>
                  <DatePicker
                    value={moment(response)}
                    onChange={(newValue) => {
                      updateResponse({
                        index,
                        value: newValue,
                      })
                    }}
                  />
                </FormControl>
              )}

              {bundle === "fixed_choice_questions" && (
                <FormControl className="fixed-choice-question">
                  {field_multi_select !== -1 && (
                    <RadioGroup
                      aria-label="Your Response"
                      name="Your Response"
                      value={response}
                      onChange={(event) => {
                        updateResponse({ index, value: event.target.value })
                      }}
                    >
                      {possibleAnswers.map((answer: string, index: number) => {
                        return (
                          <FormControlLabel
                            key={answer + "-" + index}
                            sx={() => ({
                              color: "black",
                            })}
                            value={answer}
                            control={<Radio />}
                            label={answer}
                          />
                        )
                      })}
                    </RadioGroup>
                  )}

                  {field_multi_select === -1 && (
                    <Box
                      sx={{
                        marginLeft: -2,
                        marginTop: 2,
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {possibleAnswers.map(
                        (answer: string, answerIndex: number) => {
                          return (
                            <FormControlLabel
                              style={{ marginLeft: 0, width: "47%" }}
                              control={
                                <Checkbox
                                  style={{
                                    marginRight: 5,
                                    color: theme.palette.secondary.main,
                                  }}
                                  checked={response.includes(answer)}
                                  onChange={(event) => {
                                    const selectedAnswer =
                                      possibleAnswers[answerIndex]

                                    if (event.target.checked) {
                                      updateResponse({
                                        index,
                                        value: [...response, selectedAnswer],
                                      })
                                    } else {
                                      const newResponse =
                                        response &&
                                        response.filter(
                                          (answer: string) =>
                                            answer !== selectedAnswer
                                        )
                                      updateResponse({
                                        index,
                                        value: newResponse,
                                      })
                                    }
                                  }}
                                />
                              }
                              label={answer}
                            />
                          )
                        }
                      )}
                    </Box>
                  )}
                </FormControl>
              )}
            </Box>
          )
        })}
    </Box>
  )
}
